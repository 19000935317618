import React, { useState } from 'react';

import useMedias from '../../hooks/useMedias';
import { Button } from '../../Molecules/Button/Button';
import ShapeBackground from '../../Molecules/ShapeBackground/ShapeBackground';
import TitleSection from '../../Molecules/TitleSection/TitleSection';
import { getReOrderedCarouselSlides } from '../../Utils/Utils';
import ArticleHighlight from '../ArticleHighlight/ArticleHighlight';
import Carousel from '../Carousel/Carousel';

import './HomeNews.scss';
let classNames = require('classnames');

const HomeNews = ({ newsNode, topNews, imagesArray, documentsArray }) => {
  const { getImage, getDocument } = useMedias();

  let slides = [];
  let article_list_mobile = [];

  const showMoreVal = 3;
  const [showItem, setShowItem] = useState(showMoreVal);

  topNews.forEach((item) => {
    const visuel = getImage(
      imagesArray,
      item?.node?.relationships?.field_image?.drupal_internal__mid
    );

    slides.push({
      image: visuel,
      info: {
        text: item.node?.title,
        link: getDocument(
          documentsArray,
          item.node.relationships?.field_media_pdf?.drupal_internal__mid
        )?.uri?.url
      },
      category: item.node.relationships?.field_category?.drupal_internal__tid
    });

    article_list_mobile.push({
      image: visuel,
      title: item.node?.title,
      link: getDocument(
        documentsArray,
        item.node.relationships?.field_media_pdf?.drupal_internal__mid
      )?.uri?.url,

      category: item.node.relationships?.field_category?.drupal_internal__tid
    });
  });

  const onClickShowMore = () => {
    if (showItem < article_list_mobile?.length) {
      setShowItem(showItem + showMoreVal);
    }
  };

  return (
    <section
      className={classNames(
        'home_news',
        'section_content',
        newsNode.field_backgroud_color != 'ice' &&
          newsNode.field_backgroud_color != 'navy'
          ? 'index_top'
          : null
      )}
    >
      <ShapeBackground
        top={
          newsNode.field_border_top &&
            newsNode.field_position_bordure_haut === 'gauche'
            ? 'left'
            : newsNode.field_border_top &&
            newsNode.field_position_bordure_haut != null &&
            'right'
        }
        color={
          newsNode.field_backgroud_color === 'ice'
            ? 'catskill_white'
            : newsNode.field_backgroud_color === 'navy'
              ? 'dark_bleu'
              : 'white'
        }
        bottomInfinite={newsNode.field_infinite_border}
        bottom={newsNode.field_border_bottom}
      >
        <div className="wrapper_page_xs">
          <TitleSection
            title={newsNode.field_title?.processed}
            subtitle={newsNode.body?.processed}
            type="arrow"
            h2color={
              newsNode.field_backgroud_color === 'navy' ? 'white' : 'bleu'
            }
            h4color={
              newsNode.field_backgroud_color === 'navy' ? 'white' : 'bleu'
            }
          />
        </div>
        <div className={classNames('wrapper_page_xs', 'only_desktop')}>
          <Carousel
            slides={getReOrderedCarouselSlides(slides)}
            sliderMode="articles"
          />
          <div className="btn_container">
            <Button
              primary={true}
              label={newsNode.field_link?.title}
              link={newsNode.field_link?.url}
            />
          </div>
        </div>

        <div
          className={classNames(
            'wrapper_page',
            'only_mobile_tablet',
            'cross_wrapper_extreme_right'
          )}
        >
          {article_list_mobile.map((item, i) => (
            <div
              className={classNames(i < showItem ? 'show-item' : 'hide-item')}
              key={i}
            >
              <ArticleHighlight
                category={item.category}
                title={item.title}
                image={item.image}
                linkTo={item.link}
              />
            </div>
          ))}

          <div className="btn_container">
            <Button
              primary={true}
              label={newsNode?.field_link?.title}
              //link={newsNode.field_link.uri}
              onClick={onClickShowMore}
              customClass={
                showItem >= article_list_mobile?.length ? 'hide' : ''
              }
            />
          </div>
        </div>
      </ShapeBackground>
    </section>
  );
};
export default HomeNews;
