import React, { useRef, useState, useEffect } from 'react';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { useIntl } from '../../../../plugins/publicis-gatsby-plugin-i18n';
import QuoteMark from '../../../Atoms/QuoteMark/QuoteMark';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import HomeBanner from '../../../components/HomeBanner/HomeBanner';
import HomeColasInnov from '../../../components/HomeColasInnov/HomeColasInnov';
import HomeJoinUs from '../../../components/HomeJoinUs/HomeJoinUs';
import HomeNews from '../../../components/HomeNews/HomeNews';
import HomeOurActivities from '../../../components/HomeOurActivities/HomeOurActivities';
import Seo from '../../../components/Seo/Seo';
import Layout from '../../../Layout';

let classNames = require('classnames');
const IndexPage = ({ data, pageContext }) => {
  const intl = useIntl();

  const bannerRef = useRef(null);

  const { locale } = pageContext;
  const homeNodesData = data.homeNodesData?.edges[0];
  const imagesArray = data.allImages.edges;
  const videosArray = data.allVideos.edges;
  const promotedNews = data.promotedNews.edges;
  const isEventBanner = data.evenBannerData?.edges[0].node.field_display;
  const metaTags =  data.homeNodesData?.edges[0].node.metatag;
  let metaTitle = '';
  let metaDesc = '';
  
   metaTags.forEach((meta) => {
      if(meta.attributes.name === 'title'){
           metaTitle = meta.attributes.content;
      }
      if(meta.attributes.name === 'description'){
           metaDesc = meta.attributes.content;
      }
    });

  return (
    <Layout eventBanner={isEventBanner} imagesArray={imagesArray}>
      <Seo
        title={homeNodesData?.node?.field_metatag?.title ? homeNodesData?.node?.field_metatag?.title : metaTitle}
        description={homeNodesData?.node?.field_metatag?.description ? homeNodesData?.node?.field_metatag?.description : metaDesc}
      />
      <div className={classNames('page_template', 'zero_bottom_padding')}>
        {homeNodesData?.node?.relationships?.field_blocs?.map((block, i) => {
          switch (block.__typename) {
            case 'block_content__main_banner':
              return (
                <div className="home_banner" ref={bannerRef} key={i}>
                  <HomeBanner
                    homeBannerNode={block}
                    imagesArray={imagesArray}
                    videosArray={videosArray}
                  >
                    <div className="wrapper_page">
                      <Breadcrumb
                        page_banner={true}
                        data={{
                          currentPage: {
                            title: intl.formatMessage({
                              id: 'common.goto.home',
                            }),
                            url: '/',
                          },
                          locale,
                        }}
                      />
                      <div className="intro">
                        <QuoteMark
                          text={block.field_title.processed}
                          tag="h1"
                          lang={locale}
                        />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: block.body.processed,
                          }}
                        ></div>
                      </div>
                    </div>
                  </HomeBanner>
                </div>
              );

            case 'block_content__block_innovation':
              return (
                <HomeColasInnov
                  innovationNode={block}
                  imagesArray={imagesArray}
                  key={i}
                />
              );

            case 'block_content__block_slider_nos_activites':
              return (
                <HomeOurActivities
                  activitiesAllDetails={block}
                  imagesArray={imagesArray}
                  key={i}
                />
              );

            case 'block_content__block_actualites':
              return (
                <HomeNews
                  newsNode={block}
                  topNews={promotedNews}
                  imagesArray={imagesArray}
                  documentsArray={data.allDocuments?.edges}
                  key={i}
                />
              );

            case 'block_content__block_nous_rejoindre':
              return <HomeJoinUs allJoinUsDetails={block} key={i} />;

            default:
              break;
          }
        })}

      </div>
    </Layout>
  );
};

export const query = graphql`
  query HomePageTemplate(
    $locale: String!
    $imagesID: [Int!]
    $videoID: [Int!]
    $documentsID: [Int!]
  ) {
    homeNodesData: allNodeHomepage(
      filter: { langcode: { eq: $locale }, status: { eq: true } }
    ) {
      edges {
        node {
          status
          langcode
          field_metatag {
            description
            title
          }
          metatag {
            attributes {
              content
              name
            }
          }
          relationships {
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__main_banner {
                  field_title {
                    processed
                  }
                  body {
                    processed
                  }
                  field_type_de_media
                  relationships {
                    field_image {
                      drupal_internal__mid
                    }
                    field_mobile_image {
                      drupal_internal__mid
                    }
                    field_video {
                      drupal_internal__mid
                    }
                  }
                }
                ... on block_content__block_innovation {
                  field_title {
                    processed
                  }
                  body {
                    processed
                  }
                  field_backgroud_color
                  field_infinite_border
                  field_border_top
                  field_border_bottom
                  relationships {
                    field_slides {
                      field_publish
                      field_title {
                        processed
                      }
                      field_link {
                        title
                        url
                      }
                      relationships {
                        field_image {
                          drupal_internal__mid
                        }
                        field_anchor {
                          drupal_internal__tid
                        }
                      }
                      langcode
                    }
                  }
                }
                ... on block_content__block_actualites {
                  id
                  body {
                    processed
                  }
                  field_backgroud_color
                  field_infinite_border
                  field_border_top
                  field_border_bottom
                  field_position_bordure_haut
                  field_link {
                    title
                    uri
                    url
                  }
                  field_title {
                    processed
                  }
                  drupal_internal__id
                }
                ... on block_content__block_slider_nos_activites {
                  id
                  body {
                    processed
                  }
                  field_backgroud_color
                  field_infinite_border
                  field_border_top
                  field_border_bottom
                  field_position_bordure_haut
                  field_link_title
                  field_title {
                    processed
                  }
                  relationships {
                    field_nos_activites {
                      __typename
                      ... on Node {
                        ... on node__activites {
                          title
                          path {
                            alias
                            langcode
                          }
                          relationships {
                            field_image {
                              drupal_internal__mid
                            }
                          }
                        }
                      }
                    }
                  }
                }
                
                ... on block_content__block_nous_rejoindre {
                  body {
                    processed
                  }
                  drupal_internal__id
                  field_offre_text
                  field_link {
                    title
                    url
                  }
                  field_subtitle {
                    processed
                  }
                  field_title {
                    processed
                  }
                  field_chiffre_offre
                  field_backgroud_color
                  field_infinite_border
                  field_border_top
                  field_border_bottom
                }
              }
            }
          }
        }
      }
    }

    promotedNews: allNodeArticle(
      limit: 10, 
      sort: {fields: created, order: DESC}
      filter: { langcode: { eq: $locale }, status: { eq: true } }
      ) {
      edges {
        node {
          id
          title
          sticky
          status
          langcode
          promote
          path {
            langcode
            alias
          }
          relationships {
            field_media_pdf {
              drupal_internal__mid
            }
            field_image {
              drupal_internal__mid
            }
          }
        }
      }
    }

    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
            }
          }
        }
      }
    }
    allVideos: allMediaVideo(
      filter: { langcode: { eq: "fr" }, drupal_internal__mid: { in: $videoID } }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_video_file {
              uri {
                url
              }
            }
          }
          langcode
        }
      }
    }
    allDocuments: allMediaDocument(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $documentsID }
      }
    ) {
      edges {
        node {
          langcode
          field_media_document {
            description
          }
          relationships {
            field_media_document {
              filesize
              filename
              filemime
              uri {
                url
              }
            }
          }
          drupal_internal__mid
        }
      }
    }
    evenBannerData :   allBlockContentBlockBanniereEvenementielle {
      edges {
        node {
          field_display
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default IndexPage;
