import React from 'react';

import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';

import {
  ArrowIconGoRight,
  HorizontalYellowLineShape,
} from '../../Atoms/Icons/Icons';
import useMedias from '../../hooks/useMedias';
import SocialShare from '../../Molecules/SocialShare/SocialShare';

import './ArticleHighlight.scss';

let classNames = require('classnames');

const ArticleHighlight = ({ title, linkTo, category, image }) => {
  const { processUrl } = useMedias();

  return (
    <div className="article_highlight">
      <div className="single_article">
        <div className="info_share">
          <SocialShare
            title={title}
            linkTo={linkTo}
            hidemobile={true}
            vertical={true}
          />
        </div>
        <div className={classNames('visuel', { default_visuel: !image })}>
          {image ? (
            <img
              src={processUrl(image?.uri?.url)}
              alt={title}
              style={{ height: '100%', width: '100%', objectFit: 'cover' }}
            />
          ) : (
            <StaticImage
              style={{ height: '100%', width: '100%' }}
              src="../../assets/images/home-page-banner.jpg"
              alt="placeholder"
              placeholder="blurred"
            />
          )}
        </div>
        <div className="info">
          <p>
            {linkTo?.indexOf('.pdf') > 0 ? (
              <a href={linkTo} target="_blank" rel="noreferrer">
                {title} <ArrowIconGoRight fill="#ffffff" />
              </a>
            ) : (
              <Link to={linkTo} target={category === 8 ? '_blank' : '_self'}>
                {title} <ArrowIconGoRight fill="#ffffff" />
              </Link>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

ArticleHighlight.propTypes = {
  title: PropTypes.string,
  linkTo: PropTypes.string.isRequired,
};

export default ArticleHighlight;
