import * as React from 'react';

import { Button } from '../../Molecules/Button/Button';
import GraphRH from '../../Molecules/GraphRH/GraphRH';
import ShapeBackground from '../../Molecules/ShapeBackground/ShapeBackground';
import TitleSection from '../../Molecules/TitleSection/TitleSection';

import './HomeJoinUs.scss';

let classNames = require('classnames');

const HomeJoinUs = ({ allJoinUsDetails }) => {
  return (
    <section className={
      classNames(
        'home_join_us',
        'section_content',
        (allJoinUsDetails.field_backgroud_color != 'ice' && allJoinUsDetails.field_backgroud_color != 'navy') ? 'index_top' : null
      )
    }>
      <ShapeBackground
        top={allJoinUsDetails.field_border_top && 'left'}
        color={allJoinUsDetails.field_backgroud_color === 'ice' ? 'catskill_white' : (allJoinUsDetails.field_backgroud_color === 'navy' ? 'dark_bleu' : 'white')}
        bottomInfinite={allJoinUsDetails.field_infinite_border}
        bottom={allJoinUsDetails.field_border_bottom}
      >
        <div className="wrapper_page_xs">
          <TitleSection
            title={allJoinUsDetails?.field_title?.processed}
            subtitle={allJoinUsDetails?.field_subtitle?.processed.replace(/(<([^>]+)>)/gi, '')}
            type="arrow"
            h2color="white"
            h4color="yellow"
          />
          <div className="flex_content">
            <div className="graph_rh_component">
              <GraphRH
                jobs={allJoinUsDetails.field_chiffre_offre}
                text={allJoinUsDetails.field_offre_text}
                textColor="color_white"
              />
            </div>
            <div className="text">
              <div dangerouslySetInnerHTML={{ __html: allJoinUsDetails?.body?.processed }}></div>
              <Button
                primary={true}
                label={allJoinUsDetails.field_link?.title}
                size='medium'
                link={allJoinUsDetails.field_link?.url}
              />
            </div>
          </div>
        </div>
      </ShapeBackground>
    </section>
  );
};

export default HomeJoinUs;
